import React, { useRef } from "react";
import SEO from "../../components/seo/seo";
import { useStaticQuery, graphql } from "gatsby";
import { PageParams } from "../../../types";
import Img from "gatsby-image";

import "./aplications.scss";

import { InView } from "react-intersection-observer";

import Layout from "../../components/layout/layout";
import Button from "../../components/button/button";
import Hero from "../../components/hero/hero";
import HeaderBig from "../../components/header-big/header-big";
import CallFooter from "../../components/call-footer/call-footer";
import StickyContainer from "../../components/sticky/sticky-container";
import PortfolioList from "../../components/portfolio-list/portfolio-list";
import { Portfolio, PortfolioNode } from "../../utils/portfolio";
import Section from "../../components/air-section/air-section";
import BoxList from "../../components/box-list/box-list";
import ScrollButton from "../../components/scroll-button/scroll-button";

const AplikacjePage = (params: PageParams) => {
  const language = params.pageContext.language;
  const scrollRef = useRef();
  const experienceItems = [
    {
      name: "Spotkanie",
      to: "/",
    },
    {
      name: "Przygotowanie wstępnej wyceny i&nbsp;draftu umowy",
      to: "/",
    },
    {
      name: "Przygotowanie specyfikacji, określenie funkcjonalności",
      to: "/",
    },
    {
      name: "Projekt graficzny",
      to: "/",
    },
    {
      name: "Programowanie",
      to: "/",
    },
    {
      name: "Modułowe wydania portalu",
      to: "/",
    },
    {
      name: "Opieka powdrożeniowa",
      to: "/",
    },
  ];

  const data = useStaticQuery(
    graphql`
      {
        project: file(
          relativePath: { eq: "sklepy-internetowe/yummypix-rwd.png" }
        ) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        rwd: file(relativePath: { eq: "sklepy-internetowe/rwd.png" }) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        seo: file(relativePath: { eq: "mac-mock.png" }) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        portfolio: allFile(
          filter: {
            sourceInstanceName: { eq: "portfolio" }
            extension: { eq: "mdx" }
          }
        ) {
          group(field: relativeDirectory) {
            nodes {
              id
              sourceInstanceName
              relativeDirectory
              relativePath
              childMdx {
                frontmatter {
                  hero_color
                  color
                  section_bg
                  category
                  slug
                  title
                  language
                  description
                  short_description
                  created
                  thumbnail {
                    childImageSharp {
                      fluid(maxWidth: 900, maxHeight: 600) {
                        base64
                        src
                        aspectRatio
                        sizes
                        src
                        srcSet
                      }
                    }
                  }
                  gallery_alt
                  gallery {
                    childImageSharp {
                      fluid(maxWidth: 1600) {
                        base64
                        src
                        aspectRatio
                        src
                        srcSet
                        sizes
                      }
                    }
                  }
                }
                body
              }
            }
          }
        }
      }
    `
  );
  const itemSlugs = ["wallers", "yummypix", "zaklady-miesne-kozlowski"];
  const items = Portfolio.getNodesByLanguage("pl", data.portfolio.group)
    .filter((node: PortfolioNode) => {
      return itemSlugs.indexOf(node.childMdx.frontmatter.slug) >= 0;
    })
    .map((node: PortfolioNode) => {
      return node.childMdx.frontmatter;
    });

  return (
    <>
      <SEO
        title="Aplikacje Webowe"
        description="Aplikacje webowe i bazodanowe dla firm. Tworzymy aplikacje internetowe i programy działające w sieci www. •Aplikacje www ☎ +48 665 035 451"
        lang={language}
      />
      <Hero>
        <div className="container-xs header-padding">
          <h1>Aplikacje webowe i&nbsp;bazodanowe</h1>
          <div className="header-subline">
            <h2 className="subtitle">
              Tworzymy aplikacje i&nbsp;systemy bazodanowe, wspierające biznes
            </h2>
          </div>
          <h4>
            W&nbsp;naszych realizacjach, klienci docenili: stabilność, wydajność
            oraz szybkość działania aplikacji. Zwrócono również uwagę na łatwość
            obsługi i&nbsp;intuicyjność interfejsów użytkownika oraz panelu
            administracyjnego.
          </h4>
        </div>
        <ScrollButton style={{
          position: "absolute",
          left: "50%",
          bottom: "5%",
          transform: "translateX(-50%)"
        }} to={scrollRef} />
      </Hero>
      <Section className="container" ref={scrollRef}>
        <HeaderBig
          tag="h2"
          span="Co zyskasz"
          headerText="Dlaczego warto zaprojektować aplikację w&nbsp;naszej agencji"
        />
        <p>
          Aplikacje internetowe to elastyczne, dostępne za pośrednictwem sieci
          www, rozwiązanie dla biznesowych wyzwań. Idealnie sprawdzają się do
          zdalnego zarządzania przedsiębiorstwem, automatyzacji procesów,
          koordynacji współpracy w&nbsp;rozproszonych oddziałach oraz integracji
          z&nbsp;innymi podmiotami (aplikacje B2B i&nbsp;B2C).
        </p>
      </Section>
      <Section className="">
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="">
            <Img
              fluid={data.project.childImageSharp.fluid}
              className="image-test"
              alt="Bridge"
              title="Bridge"
            />
            <p className="align-center container">
              <strong>
                Stworzyliśmy 2016 roku Cantora Systems - software house, którego
                zadaniem jest udostępnienie specjalistów do wymagających zadań
                programistycznych.
              </strong>
            </p>
          </div>
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="01</br>Wydajność"
              headerText="Zoptymalizujemy większość procesów w&nbsp;Twojej firmie"
            />
            <p>
              Aplikacje internetowe mogą być wydajnym systemem, działającym za
              pośrednictwem przeglądarki internetowej. Ich głównym celem jest
              optymalizacja zadań związanych z&nbsp;funkcjonowaniem
              przedsiębiorstwa. Skutecznie zwiększają one wydajność pracy
              w&nbsp;firmie poprzez automatyzację powtarzających się zadań.
            </p>
            <p>
              <strong>
                Projektując aplikację w&nbsp;naszej agencji, jesteśmy pewni że:
              </strong>
            </p>
            <ul>
              <li>zoptymalizujesz procesy działające w&nbsp;firmie,</li>
              <li>
                zaoszczędzisz na czasie pracowników, optymalizując koszty
                zatrudnienia.
              </li>
            </ul>
          </div>
        </StickyContainer>
      </Section>
      <Section className="">
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="02 Skalowalność"
              headerText="Zapewnimy dostępność aplikacji, niezależnie od miejsca oraz ilości użytkowników"
            />
            <p>
              Dedykowane aplikacje internetowe pozwolą na zarządzanie firmą przy
              pomocy komputera, tabletu lub telefonu. Wystarczy dostęp do
              internetu żeby korzystać ze wszystkich funkcjonalności systemu
              oraz nieograniczonej bazy informacji, aktualizowanej w&nbsp;czasie
              rzeczywistym, przez wielu użytkowników.
            </p>
            <p>
              Aplikacje nie wymagają instalacji na urządzaniach oraz zakupu
              dodatkowego sprzętu. Wprowadza również uniwersalność
              w&nbsp;przypadku systemu operacyjnego na którym jest używana.
            </p>
            <p>
              <strong>Jesteśmy pewni, że:</strong>
            </p>
            <ul>
              <li>
                przygotujemy system zapewniający dostępność na różnego typu
                urządzeniach
              </li>
              <li>
                oprogramowanie będzie prawidłowo działać na wielu systemach
                operacyjnych.
              </li>
            </ul>
          </div>
          <div data-sticky className="">
            <Img
              fluid={data.seo.childImageSharp.fluid}
              className="image-test"
              alt="Bridge"
              title="Bridge"
            />
          </div>
        </StickyContainer>
      </Section>
      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="">
            <Img
              fluid={data.rwd.childImageSharp.fluid}
              className="image-test"
              alt="Bridge"
              title="Bridge"
            />
          </div>
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="03 Raporty i&nbsp;podsumowania"
              headerText="Przygotujemy funkcjonalności które pozwolą na skuteczne działanie przedsiębiorstwa"
            />
            <p>
              Aplikacje do zarządzania klientami, CRM, ERP, zarządzanie
              rezerwacjami lub zamówieniami może być naprawdę proste.
              Przygotujemy każdy rodzaj dedykowanego oprogramowania.
            </p>
            <p>
              Przy każdej realizacji, na pierwszym miejscu stawiamy potrzeby
              klientów. Dbamy o&nbsp;wysoką jakość UI, UX oraz funkcjonalność
              panelu administracyjnego. Dobre praktyki programistyczne pozwalają
              nam na osiągnięcie zamierzonych wyników.
            </p>
            <p>
              <strong>Jesteśmy pewni, że:</strong>
            </p>
            <ul>
              <li>ułatwimy kontrolę nad działaniem firmy,</li>
              <li>
                technologie i&nbsp;rozwiązania spełniają stawiane przed nimi
                zadania,
              </li>
              <li>
                klienci są zadowoleni z&nbsp;naszego podejścia przy realizacji
                aplikacji.
              </li>
            </ul>
          </div>
        </StickyContainer>
      </Section>
      <Section className="">
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="04 Integracje"
              headerText="Integrujemy, exportujemy i&nbsp;importujemy. Pozwalamy na kontrolę danych przepływających przez przedsiębiorstwo"
            />
            <p>
              Możemy zintegrować aplikację nie tylko z&nbsp;wieloma systemami
              płatności, usługami Google, LinkedIn, Tinder, Youtube, Booking lub
              Facebook, Allegro, ale również podpiąć usługi wystawiane przez
              innych (mniejszych) dostawców oprogramowania.
            </p>
            <p>
              Wystawimy również API, które umożliwi innym aplikacjom korzystanie
              z&nbsp;Twoich zasobów. Mamy doświadczenie w&nbsp;budowaniu
              w&nbsp;celu użyczenia danych. Może to prowadzić do uzyskania
              dodatkowych przychodów.
            </p>
            <p>
              <strong>Zapewniamy wszechstronność w zakresie:</strong>
            </p>
            <ul>
              <li>funkcjonalności oprogramowania</li>
              <li>integracji systemów działających online.</li>
            </ul>
          </div>
          <div data-sticky className="">
            <Img
              fluid={data.seo.childImageSharp.fluid}
              className="image-test"
              alt="Bridge"
              title="Bridge"
            />
            <div className="align-center">
              <Button color="primary" type="default" to="/" language="pl">
                Dowiedz się więcej o&nbsp;Fennec CMS
              </Button>
            </div>
          </div>
        </StickyContainer>
      </Section>
      <Section className="">
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="">
            <Img
              fluid={data.rwd.childImageSharp.fluid}
              className="image-test"
              alt="Bridge"
              title="Bridge"
            />
          </div>
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="05</br>Wątpliwości"
              headerText="Chętnie dzielimy się naszą wiedzą i&nbsp;odpowiemy na wszystkie pytania"
            />
            <p>
              Jeżeli nie jesteś pewien, czy ta usługa jest idealna dla Ciebie?
              Dowiedz się więcej o&nbsp;wszystkich aspektach aplikacji webowych
              kontaktując się z naszym ekspertem.
            </p>
            <p>
              <strong>Możesz być pewien, że:</strong>
            </p>
            <ul>
              <li>przedstawimy Tobie najlepsze możliwe rozwiązania,</li>
              <li>nasi konsultancji doradzą Ci w podjęciu decyzji.</li>
            </ul>
          </div>
        </StickyContainer>
      </Section>
      <Section>
        <div className="align-center">
          <Button color="primary" type="inversed" to="/kontakt" language="pl">
            Darmowa wycena aplikacji webowej
          </Button>
        </div>
      </Section>
      <Section className="container">
        <HeaderBig
          tag="h2"
          span="Aplikacje internetowe"
          headerText="Kilka naszych realizacji"
        />
        <PortfolioList items={items}></PortfolioList>
        <div className="align-center">
          <Button
            color="primary"
            type="inversed"
            to="/realizacje"
            language="pl"
          >
            Zobacz wszystkie realizacje
          </Button>
        </div>
      </Section>
      <Section backgroundColor="#000" className="container">
        <HeaderBig
          tag="h2"
          span="Profity aplikacji</br>webowych"
          spanColor="#484848"
          headerText="Po co tworzyć profesjonalną aplikację internetową"
          color="#50817b"
        />
        <BoxList
          boxItems={[
            {
              title: `Aplikacje B2B i&nbsp;B2C`,
              span: "01",
              description:
                "Postaw na dedykowany system webowy, dopasowany do wymagań Twojego biznesu. Zyskaj dostęp do danych szybko zdobywaj nowych klientów i&nbsp;optymalizuj procesy firmowe.",
            },
            {
              title: `Systemy do zarządzania`,
              span: "02",
              description: "Elektroniczny obieg dokumentów, zamówienia, generowanie raportów, zarządzanie bazą klientów to zadania w których zdobyliśmy już doświadczenie.",
            },
            {
              title: `Platformy konkursowe`,
              span: "03",
              description: "Możemy przygotować jeden system dla aplikacji mobilnych i desktopowych umożliwiając dostęp do nieograniczonej liczby użytkowników internetu.",
            },
          ]}
        />
      </Section>
      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="container ">
            <HeaderBig
              tag="h2"
              span="Przegieg współpracy"
              headerText="Etapy tworzenia aplikacji webowej"
            />
            <p>
              Przed złożeniem zapytania ofertowego, postaraj się odpowiedzieć na
              pytanie "po jaką usługę przychodzisz do agencji". Zastanów się,
              jakiego zakresu kompetencji potrzebujesz, w&nbsp;jakim obszarze
              mamy wspierać Twój pomysł? Podsumuj swoje oczekiwania
              i&nbsp;przygotuj pytania.
            </p>
            <p>
              Aby ułatwić to zadanie, przygotowaliśmy etapy prawidłowo
              zaplanowanej współpracy przy tworzeniu aplikacji webowej.
            </p>
            <div style={{ textAlign: "center" }}>
              <Button
                color="primary"
                type="default"
                to="/kontakt"
                language="pl"
              >
                Wyceń swoją aplikację
              </Button>
            </div>
          </div>
          <ul className="experience count container">
            {experienceItems.map((item, index) => {
              return (
                <InView key={index} rootMargin="-49% 0px -49% 0px">
                  {({ inView, ref, entry }) => (
                    <li ref={ref} className={inView ? "active" : ""}>
                      <h3 dangerouslySetInnerHTML={{ __html: item.name }}></h3>
                    </li>
                  )}
                </InView>
              );
            })}
          </ul>
        </StickyContainer>
      </Section>
      <CallFooter />
    </>
  );
};

export default AplikacjePage;
